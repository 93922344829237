import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

declare let $: any;

@Component({
  selector: "app-modal-sign",
  templateUrl: "./modal-sign.component.html",
  styleUrls: ["./modal-sign.component.scss"],
  providers: [MatSnackBar],
})
export class ModalSignComponent implements OnInit, OnDestroy {
  @Input() step: string;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

  closeModal() {
    $(".modal").modal("hide");
  }
}
