import { Component } from "@angular/core";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ActivatedRoute,
} from "@angular/router";
import { filter, take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService
  ) {
    translate.addLangs(["ru", "en"]);
    if (localStorage.getItem("locale")) {
      const browserLang = localStorage.getItem("locale");
      translate.use(browserLang.match(/ru|en/) ? browserLang : "ru");
    } else {
      localStorage.setItem("locale", "ru");
      translate.setDefaultLang("ru");
    }

    activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams && queryParams.utm_source) {
        localStorage.setItem("utmSource", queryParams.utm_source);
      }
    });
    router.events
      .pipe(
        filter(
          (e) =>
            e instanceof NavigationStart ||
            e instanceof NavigationEnd ||
            e instanceof NavigationCancel ||
            e instanceof NavigationError
        )
      )
      .subscribe(async (e) => {
        if (e instanceof NavigationEnd) {
          document.documentElement.scrollTop = 0;
        }
      });
  }
}
