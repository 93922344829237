<div class="modal fade" id="signingContractModal" tabindex="-1" role="dialog" aria-labelledby="signingContractTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="close-block">
        <img data-dismiss="modal" src="assets/images/home/close.svg" />
      </div>
      <div class="modal-body">
        <h3>Подписание договора</h3>
        <div class="col-12 text-center">
          <p>Загрузите фотографии</p>
          <small class="alert alert-warning d-block">Подписание доступно с 00:00 до 16:25 в рабочие дни <br>(по времени
            Алматы)</small>
        </div>
      </div>
      <div class="col-12 mb-3 d-flex">
        <mat-checkbox [(ngModel)]="check1" (ngModelChange)="onScoringChange()">
          <p style='margin-bottom: 0'>
            С <a [href]="printApplication" target="_blank" class="nav-tabs text-white">заявлением</a> на получение
            микрокредита по ИП ознакомлен(а) и согласен(а)
          </p>
        </mat-checkbox>
      </div>
      <div class="col-12 mb-3 d-flex">
        <mat-checkbox [(ngModel)]="check2" (ngModelChange)="onScoringChange()">
          <p style='margin-bottom: 0'>
            С <a [href]="printCreditContract" target="_blank" class="nav-tabs text-white">договором</a> и <a
              [href]="printCreditContractSchedule" target="_blank" class="nav-tabs text-white">графиком погашения</a>
            ознакомлен(а) и согласен(а)
          </p>
        </mat-checkbox>
      </div>
      <svg class="inline-svg">
        <symbol id="check" viewbox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg>

      <div class="col-12 mt-3 file pl-4 pr-4">
        <div class="file-area">
          <input type="file" (change)="handleFileCardFront($event.target.files)" accept="image/*" required>
          <div class="file-dummy">
            <div class="success">
              <span>Загружено</span>
              <img id="cardFront" src="#" alt="Лицевая сторона удостоверения личности" />
            </div>
            <div class="default">Лицевая сторона удостоверения личности</div>
          </div>
        </div>
        <div class="file-area">
          <input type="file" (change)="handleFileCardBack($event.target.files)" accept="image/*" required>
          <div class="file-dummy">
            <div class="success">
              <span>Загружено </span>
              <img id="cardBack" src="#" alt="Обратная сторона удостоверения личности" />
            </div>
            <div class="default">Обратная сторона удостоверения личности</div>
          </div>
        </div>
        <div class="file-area">
          <input type="file" (change)="handleFilePhoto($event.target.files)" accept="image/*" required>
          <div class="file-dummy">
            <div class="success">
              <span>Загружено </span>
              <img id="photo" src="#" alt="Селфи с удостоверением личности" />
            </div>
            <div class="default">Селфи с удостоверением личности</div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3 pl-4 pr-4 text-center" *ngIf="counter < 10">
        <p>Попыток для загрузки фото осталось {{counter}}</p>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-3">
          </div>
          <div class="col-md-6 mt-3">
            <button class="btn w-100 text-white" [ngClass]="disabled ? 'btn-secondary' : ''" (click)="sendFiles()"
              [disabled]="disabled || counter === 0" style="height: 70px;
              background: #FF9918;
              border-radius: 0;
              font-size: 20px;
              padding: 0 30px;">
              Подтвердить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
