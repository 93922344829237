<div class="not-found">
  <div class="navbar">
    <div class="container">
      <a routerLink="/" class="logo">
        <img src="assets/images/nav-bar/IskerСredit.svg" alt="IskerСredit"/>
      </a>
    </div>
  </div>
  <div class="body">
    <div class="container">
      <h3>Ошибка</h3>
      <h1>404</h1>
      <p>
        Страница которую вы ищите не найдена, или перемещена по другому адресу.
      </p>
      <a routerLink="/">
        Вернуться на главную страницу.
      </a>
    </div>
  </div>
</div>
