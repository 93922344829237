<div class="modal"
    id="loaderStaticBackdrop"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="loaderStaticBackdropLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="commentary">{{ text }}</div>
        <div class="spinner-wrapper">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </div>
