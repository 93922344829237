import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-modal-success",
  templateUrl: "./modal-success.component.html",
  styleUrls: ["./modal-success.component.scss"],
  providers: [MatSnackBar],
})
export class ModalSuccessComponent implements OnInit, OnDestroy {
  @Input() url: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}
}
