<div class="modal fade" id="cardModal" tabindex="-1" role="dialog" aria-labelledby="requestModalTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="close-block">
        <img data-dismiss="modal" src="assets/images/home/close_black.svg"/>
      </div>
      <div class="modal-body">
        <h3 class="modal-header-title">Внести оплату</h3>
        <div class="col-12 form">
          <div class="form-group row">
            <label for="contract-number" class="col-12 col-md-6 col-lg-6 col-xl-5 col-form-label">
              Номер договора
            </label>
            <div class="col-12 col-md-6">
              <input type="text"
                name="contract-number"
                #contract_number
                id="contract-number"
                disabled
                class="form-control"
                value="{{ contract?.contract_number }}"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="contract-number" class="col-12 col-md-6 col-lg-6 col-xl-5 col-form-label">
              Дата заключения
            </label>
            <div class="col-12 col-md-6">
              <input type="text"
                name="contract-number"
                #contract_number
                id="contract-number"
                disabled
                class="form-control"
                value="{{ contract?.date_of_conclusion | date: 'dd.MM.yyyy' }}"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="contract-number" class="col-12 col-md-6 col-lg-6 col-xl-5 col-form-label">
              Сумма к оплате
            </label>
            <div class="col-12 col-md-6">
              <input type="text"
                name="planned-payment-amount"
                #contract_number
                id="planned-payment-amount"
                class="form-control"
                disabled
                value="{{ contract?.planned_payment_amount | numseparator }}"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="planned-payment-amount" class="col-12 col-md-6 col-lg-6 col-xl-5 col-form-label">
              Введите сумму к оплате
            </label>
            <div class="col-12 col-md-6">
              <input type="text"
                name="planned-payment-amount"
                #contract_number
                id="planned-payment-amount"
                class="form-control"
                value="{{ contract?.planned_payment_amount | numseparator }}"
              />
            </div>
          </div>
          <h3 class="modal-header-title" style="margin-bottom:15px;">Выберите карту: </h3>

          <a>Добавить карту</a>

          <div class="send-btn-block">
            <button mat-flat-button
              color="primary"
              type="submit"
              (click)="makePayment()"
              class="btn btn-send">
              Внести оплату
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
