<div class="modal fade"
  id="defaultModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="sendContractTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div 
      class="modal-content"
      style="width: 90%; margin: 0 auto; box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.7098039215686275)">
      <div class="close-block">
        <img data-dismiss="modal" src="assets/images/home/close.svg"/>
      </div>
      <div class="modal-body form">
        <div class="row">
          <div class="col-12">
            <p>{{text}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
