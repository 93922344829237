// core
import { Component, OnInit, OnDestroy, AfterContentInit } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";

// models
import { User } from "../../models/user";

// services
import { UnsubscribeService } from "../../services/unsubscribe/unsubscribe.service";
import { MyLocalStorageService } from "src/app/services/local-storage/local-storage.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";

declare let $: any;
@Component({
  selector: "app-navbar-header",
  templateUrl: "./navbar-header.component.html",
  styleUrls: ["./navbar-header.component.scss"],
})
export class NavbarHeaderComponent implements OnInit, OnDestroy {
  private subscribes = new Array<any>();
  public token: string;
  public lang: string;

  constructor(
    private router: Router,
    private unsubscribeService: UnsubscribeService,
    private localStorage: MyLocalStorageService,
    private authService: AuthService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.token = this.localStorage.getToken();
    this.lang = localStorage.getItem("locale") || 'ru';
  }

  public anchor(url: string, id: string) {
    if (this.router.url === url) {
      this.scrollToElement(id);
    } else {
      this.router.navigate(["/"]).then(() => {
        this.scrollToElement(id);
      });
    }
  }

  public scrollToElement(id: string) {
    const element: HTMLElement = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 100,
        behavior: "smooth",
      });
    }
  }

  public logout() {
    this.authService.logout();
  }

  changeLang(language: string) {
    localStorage.setItem("locale", language);
    this.translate.use(language);
    this.lang = language;
  }

  ngOnDestroy() {
    this.unsubscribeService.unsubscribings(this.subscribes);
  }
}
