import { Component, OnInit, OnDestroy } from "@angular/core";
import { DocumentService } from "src/app/services/document/document.service";

@Component({
  selector: "app-financing",
  templateUrl: "./financing.component.html",
  styleUrls: ["./financing.component.scss"],
  providers: [DocumentService],
})
export class FinancingComponent implements OnInit, OnDestroy {
  dataOTP = {
    phone: "",
    uuid: "",
  };
  constructor() {}

  ngOnInit() {}

  sendModalOTP({ phone, uuid }) {
    this.dataOTP.phone = phone;
    this.dataOTP.uuid = uuid;
  }

  ngOnDestroy() {}
}
