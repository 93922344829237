<app-navbar-header></app-navbar-header>

<div class="documents">
  <div class="container">
    <h1>{{'Documents' | translate}}</h1>

    <div class="item" *ngFor="let document of documents;  let i = index">
      <img src="assets/images/pdf.svg" alt="{{ document.title }}" title="{{ document.title }}" />
      <a href="{{document.document}}" target="_blank">{{i+1}}. {{ document.title }}</a>
    </div>
  </div>
</div>

<app-footer></app-footer>
