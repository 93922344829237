<app-navbar-header></app-navbar-header>

<div class="products">
  <div class="container">
    <h1>{{'Products' | translate}}</h1>

    <div class="row">
      <div class="col-12">
        <div class="product row mx-2" *ngFor="let product of products; let i = index">
          <div class="product__content p-md-4 p-3 col-lg-8 col-12 d-lg-none d-block">
            <div>
              <h2>{{ product.name }}</h2>

              <p>{{ product.description }}</p>
            </div>
          </div>
          <div class="product__content p-md-4 p-3 col-lg-8 col-md-7 col-12 order-md-1 order-2">
            <div class="d-lg-block d-none">
              <h2>{{ product.name }}</h2>

              <p>{{ product.description }}</p>
            </div>
            <div>
              <div class="product__pay">
                <div>
                  <h6>{{'The Term Of Financing' | translate}}</h6>
                  <ul>
                    <li>
                      <span>{{'From' | translate}} {{product.period_limits.lower}} {{'month' | translate}}.</span>
                    </li>
                    <li>
                      <span>{{'To' | translate}} {{product.period_limits.upper}} {{'month' | translate}}.</span>
                    </li>
                  </ul>
                </div>
                <div>
                  <h6>{{'Amount in KZT' | translate}}</h6>
                  <ul>
                    <li>
                      <span>{{'From' | translate}} {{formatNumber(product.principal_limits.lower)}}</span>
                    </li>
                    <li>
                      <span>{{'To' | translate}} {{formatNumber(product.principal_limits.upper)}}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <button mat-flat-button color="primary" class="btn btn-primary mt-2 py-1 px-4 w-auto"
                  data-toggle="modal" data-target="#requestModal"
                  (click)="setProduct(product)">{{'Apply now' | translate}}</button>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-5 col-12 p-0 d-flex align-items-end order-md-2 order-1">
            <img [src]="product.image" alt="" class="w-100" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<!-- Modal -->
<app-modal-application (sendModalOTP)="sendModalOTP($event)" [selectedProduct]="product" [products]="products"
  [productAmount]="product.principal_limits.lower" [productPeriod]="product.period_limits.lower">
</app-modal-application>
<app-modal-otp-confirm [uuid]="dataOTP.uuid" [phone]="dataOTP.phone"></app-modal-otp-confirm>
