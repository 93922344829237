<app-navbar-header></app-navbar-header>

<div class="documents">
  <div class="container">
    <h1 class="mb-5">Как получить займ?</h1>
  </div>

  <div class="container" id="how-to-get-the">
    <div class="row loan-block">
      <div class="col-md-4 card-wrapper">
        <div class="card-block">
          <div class="square">01</div>
          <p>
            Заполните заявление-анкету
            на сайте, указав необходимую сумму.
          </p>
        </div>
      </div>
      <div class="col-md-4 card-wrapper">
        <div class="card-block">
          <div class="square">02</div>
          <p>
            Дождитесь предварительного
            решения по вашей заявке. Менеджер свяжется с вами.
          </p>
        </div>
      </div>
      <div class="col-md-4 card-wrapper">
        <div class="card-block">
          <div class="square">03</div>
          <p>
            Ожидайте приезд менеджера на место бизнеса для финансового анализа.
          </p>
        </div>
      </div>

      <div class="col-md-4 card-wrapper">
        <div class="card-block">
          <div class="square">04</div>
          <p>
            После одобрения заявки ожидайте курьера для подписания всех
            необходимых документов (курьерская доставка не оплачивается).
          </p>
        </div>
      </div>
      <div class="col-md-4 card-wrapper">
        <div class="card-block">
          <div class="square">05</div>
          <p class="description">
            Поступление денежных средств на счет вашего ИП.
          </p>
        </div>
      </div>
      <div class="col-md-4 card-wrapper">
        <div class="card-block-fill">
          <p class="title" data-toggle="modal" data-target="#requestModal">
            Заполнить заявление
            <img src="assets/images/home/arrow.svg" />
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="our-advantages">
    <div class="container">
      <h1>Наши преимущества</h1>

      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 advantages-card">
          <img src="assets/images/home/our-advantages/loss.svg" alt="Низкая ставка" />
          <h3>Низкая ставка</h3>
          <p>
            Возьмите нужную <br />
            Вам сумму всего<br />
            под 0,07% в день
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 advantages-card">
          <img src="assets/images/home/our-advantages/purse.svg" alt="Низкая ставка" />
          <h3>Никакого залога</h3>
          <p>
            Получения займа <br />
            без предоставления залога
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 advantages-card">
          <img src="assets/images/home/our-advantages/choices.svg" alt="Низкая ставка" />
          <h3>Минимум требований</h3>
          <p>
            Вам не потребуется заполнять сотни<br> полей и приезжать в наш офис.
          </p>
        </div>


        <div class="col-12 col-sm-6 col-md-4 advantages-card">
          <img src="assets/images/home/our-advantages/tax.svg" alt="Отсутствие комиссии" />
          <h3>Отсутствие комиссии</h3>
          <p>
            Получение нужной<br />
            суммы без каких-либо комиссий<br />
            и страховых взносов
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 advantages-card">
          <img src="assets/images/home/our-advantages/positive-vote.svg" alt="Мгновенное решение" />
          <h3>Мгновенное решение</h3>
          <p>
            Наша новая система<br />
            позволяет ускорить<br />
            время рассмотрения выдачи
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 advantages-card">
          <img src="assets/images/home/our-advantages/customer-service.svg" alt="Работаем круглосуточно" />
          <h3>Работаем круглосуточно</h3>
          <p>
            Доступность 24/7,<br />
            без выходных и<br />
            праздничных дней
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<app-modal-application (sendModalOTP)="sendModalOTP($event)"></app-modal-application>
<app-modal-otp-confirm [uuid]="dataOTP.uuid" [phone]="dataOTP.phone"></app-modal-otp-confirm>

<app-footer></app-footer>
