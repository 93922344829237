import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TimeSnackbarShow } from "src/app/config";
import { Subject } from "rxjs";
import { UserService } from "src/app/services/user/user.service";

declare let $: any;

@Component({
  selector: "app-modal-payment",
  templateUrl: "./modal-payment.component.html",
  styleUrls: ["./modal-payment.component.scss"],
  providers: [MatSnackBar],
})
export class ModalPaymentComponent implements OnInit, OnDestroy {
  @Input() contract: any = {};

  public cards: any = [];
  public checkToValid = false;
  public requestErrors: any = {};

  public amount: number = 0;

  private destroyed$ = new Subject();

  public deletingCard: any = null;

  public isLoad: boolean = false;

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    window["getCardList"] = () => {
      this.getCardList();
    };
    if (this.contract.contract_number) {
      this.getCardList();
    }
  }

  ngOnChanges() {
    if (this.contract && this.contract.contract_number) {
      this.getCardList();
      console.log(
        this.contract.payment_schedule.filter(
          (_) =>
            new Date(_.target_dates) >=
            new Date(Date.now() - 24 * 60 * 60 * 1000 * 30)
        )
      );
      this.amount = this.contract.payment_schedule.filter(
        (_) =>
          new Date(_.target_dates) >=
          new Date(Date.now() - 24 * 60 * 60 * 1000 * 30)
      )[0].payment;
    }
  }

  public getCardList() {
    this.cards = [];
    this.isLoad = true;
    this.userService
      .getCardList(this.contract.contract_number)
      .subscribe((_) => {
        this.isLoad = false;
        this.cards = _;
        this.cards.sort((a, b) => a.id - b.id);
        console.log(_);
      });
  }

  public selectedCard: any = null;

  public makePayment() {
    window["showLoader"]("Идёт оплата, пожалуйста подождите");
    this.userService
      .pay({
        amount: this.amount,
        card_id: this.selectedCard.id,
        contract_number: this.contract.contract_number,
      })
      .subscribe(
        (_) => {
          // window["hideLoader"]();
          this.snackBar.open("Оплата прошла успешно!", null, {
            duration: TimeSnackbarShow,
            panelClass: "success-snackbar",
            verticalPosition: "top",
          });
          window.location.reload();
        },
        (error) => {
          console.log(error);
          // window["hideLoader"]();
          this.snackBar.open(`Оплата не прошла: ${error.error}`, null, {
            duration: TimeSnackbarShow,
            panelClass: "error-snackbar",
            verticalPosition: "top",
          });
        }
      );
    $("#modalPayment").modal("hide");
  }

  onAmountChanged(newValue) {
    this.amount = Math.max(newValue, 0);
  }

  addCard() {
    this.userService
      .addCard({
        contract_number: this.contract.contract_number,
      })
      .subscribe(
        (_: any) => {
          console.log(_);
          window.location.href = _.redirect_url;
        },
        (error) =>
          this.snackBar.open(`Произошла ошибка: ${error.error}`, null, {
            panelClass: "error-snackbar",
            duration: 5000,
            verticalPosition: "top",
          })
      );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  updateAutowriteOff(card) {
    this.cards.map((_) => {
      if (_ == card) return;
      this.userService.updateCard({
        ...card,
        auto_writeoff: false,
      });
    });
    console.log(card);
    this.userService.updateCard(card).subscribe((_) => this.getCardList());
  }

  deleteCard() {
    this.userService.deleteCard(this.deletingCard.id).subscribe((_: any) => {
      this.getCardList();
      $("#deleteCard").modal("hide");
    });
  }

  cancelDeletion() {
    $("#deleteCard").modal("hide");
  }
}
