<div class="modal fade" id="modalPayment" tabindex="-1" role="dialog" aria-labelledby="requestModalTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="close-block">
        <img data-dismiss="modal" src="assets/images/home/close.svg"/>
      </div>
      <div class="modal-body">
        <h3>Внести оплату</h3>
        <div class="col-12 col-xl-8 form">
          <div class="row center">
              <label class="col-6">Номер договора</label>
              <input class="col-6" [value]='contract?.contract_number' disabled>
          </div>
          <div class="row center">
              <label class="col-6">Дата заключения</label>
              <input class="col-6" [value]='contract?.date_of_conclusion' disabled>
          </div>
          <div class="row center">
              <label class="col-6" >Сумма к оплате</label>
              <input class="col-6" [value]='amount' disabled>
          </div>
          <div class="row center">
              <label class="col-6" >Введите сумму к оплате</label>
              <input class="col-6" [(ngModel)]='amount' (ngModelChange)='onAmountChanged($event)' type='number'>
          </div>
        </div>
        <h4>Выберите карту:</h4>
        <div class="row tabble-label-or-somethin">
          <div class="col-3">Выбор карты</div>
          <div class="col-7">Производить автосписание средств в счет погашения ежемесячного платежа по договору</div>
          <div class="col-2">Удалить карту</div>
        </div>

        <mat-progress-spinner mode="indeterminate" *ngIf='isLoad'></mat-progress-spinner>
        <div class="row" *ngFor="let card of cards; index as i">
          <div class="col-3" >
            <input type='radio' class="big-fucking-radio" [value]='card' [(ngModel)]='selectedCard' [disabled]="card.status != 'REGISTERED'">
            {{ card.card_masked_number }}
          </div>
          <div class="col-7">
            <mat-checkbox *ngIf="card.status == 'REGISTERED'" class="mb-3 d-inline-block" [(ngModel)]="card.auto_writeoff"
              (change)="updateAutowriteOff(card)">
            </mat-checkbox>
            <div style='color: red' *ngIf="card.status != 'REGISTERED'">
              Данную карту нельзя установить для автосписания. По карте на завершена регистрация. Рекомендуем удалить карту и добавить повторно.
            </div>
          </div>
          <a class="col-2" data-toggle="modal" data-target="#deleteCard" (click)="deletingCard = card" [class]="selectedCard == card || card.status != 'REGISTERED'">Удалить карту</a>
        </div>
        <div class="row">
          <div class="col-3" *ngIf="cards.length < 3">
            <button class="plus">+</button>
            <button class="add-card" (click)="addCard()">Добавить карту</button>
          </div>
          <div class="col-3" *ngIf="cards.length >= 3">
            Добавленно максимум карт (3)
          </div>
        </div>

        <div class="modal fade" id="deleteCard" tabindex="-1" role="dialog" aria-labelledby="requestModalTitle"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class='modal-body'>
                <div class="close-block">
                  <img data-dismiss="modal" data-target="#deleteCard" src="assets/images/home/close.svg" />
                </div>
                <h3>Вы действительно хотите удалить карту {{ deletingCard?.card_masked_number }}?</h3>
                <div class="send-btn-block">
                  <button mat-flat-button color="primary" type="submit" (click)="deleteCard()" class="btn btn-send">
                    Удалить
                  </button>
                  <button mat-flat-button color="secondary" type="submit" (click)="cancelDeletion()"
                    class="btn btn-send btn-other">
                    Отмена
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="send-btn-block">
          <button [disabled]="!selectedCard || amount == 0" mat-flat-button color="primary" type="submit" (click)="makePayment()" class="btn btn-send">
            Внести оплату
          </button>
          <button data-dismiss="modal" mat-flat-button color="secondary" type="submit" data-dismiss="modal"
            class="btn btn-send btn-other">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
