<div class="modal fade"
  id="wooppayModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="wooppayTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="close-block">
        <img data-dismiss="modal" src="assets/images/home/close.svg"/>
      </div>
      <div class="modal-body">
        <h3>Внести оплату через 
          Wooppay</h3>
        <div class="col-12 form">
          <input type="number"
            name="sum"
            id="sum"
            required
            placeholder="Введите сумму пополнения"
            [(ngModel)]="sum"
            (focus)="checkToValid = true"
            [ngStyle]="{'border-color': checkToValid && (!sum || requestErrors.amount) ? 'red' : ''}"
          />

          <div class="send-btn-block">
            <button mat-flat-button
              color="primary"
              type="submit"
              (click)="pay(sum);"
              class="btn btn-send"
              [disabled]="!sum || requestErrors.amount">
              Оплатить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
