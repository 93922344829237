<div class="modal fade" id="modalSuccess" tabindex="-1" role="dialog" aria-labelledby="requestModalTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="close-block">
        <img data-dismiss="modal" src="assets/images/home/close.svg" />
      </div>
      <div class="modal-body">
        <h5>Поздравляем с успешным подписанием!</h5>
        <p>Подписанный договор можно посмотреть <a href="{{ url }}" target="_blank">здесь</a></p>
      </div>
    </div>
  </div>
</div>
