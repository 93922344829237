<div class="modal fade"
  id="variantModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="sendContractTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div 
      class="modal-content"
      style="width: 90%; margin: 0 auto; box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.7098039215686275)">
      <div class="close-block">
        <img data-dismiss="modal" src="assets/images/home/close.svg"/>
      </div>
      <div class="modal-body form">
        <div class="row">
          <div class="col-12">
            <p>Вы можете воспользоваться услугами курьера, чтобы подписать документы или же продолжить электронное подписание.</p>
          </div>
          <div class="col-md-7">
            <button 
              class="w-100 btn text-white" 
              (click)="onCourier()" 
              style="height: 40px;
              background: #1C3D9F;
              border-radius: 0;
              font-size: 14px;
              padding: 0 15px;">
              Подписание через курьера
            </button>
          </div>
          <div class="col-md-5">
            <button
              class="w-100 btn text-white"
              (click)="closeModal()"
              style="height: 40px;
              background: #1C3D9F;
              border-radius: 0;
              font-size: 14px;
              padding: 0 15px;">
              Продолжить
            </button>    
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
