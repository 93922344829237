<app-navbar-header></app-navbar-header>

<div class="faq">
  <div class="container">
    <h1>{{'Questions and answers' | translate}}</h1>
    <div class="accordion" id="accordionQuestions">
      <div *ngFor="let faq of faqList; let i = index;">
        <div id="headingOne">
          <h3 data-toggle="collapse" [attr.data-target]="'#collapse' + faq.id" aria-expanded="false"
            aria-controls="collapseOne">
            {{ i+1 }}. {{ faq.question }}
          </h3>
        </div>

        <div id="collapse{{ faq.id }}" class="collapse" aria-labelledby="headingOne" data-parent="#accordionQuestions">
          <div class="collapse-content">
            <p [innerHTML]="faq.answer">
            </p>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
