import { Component, OnInit, OnDestroy } from "@angular/core";
import { DocumentService } from "src/app/services/document/document.service";
import { UnsubscribeService } from "src/app/services/unsubscribe/unsubscribe.service";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.scss"],
  providers: [DocumentService],
})
export class DocumentsComponent implements OnInit, OnDestroy {
  public documents: Array<Document>;
  private subscribes = [];

  constructor(
    private documentService: DocumentService,
    private unsubscribeService: UnsubscribeService
  ) {}

  ngOnInit() {
    this.getDocuments();
  }

  private getDocuments() {
    this.subscribes.push(
      this.documentService.getDocuments().subscribe(
        (res: Array<Document>) => {
          this.documents = res;
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }

  ngOnDestroy() {
    this.unsubscribeService.unsubscribings(this.subscribes);
  }
}
