<app-navbar-header></app-navbar-header>

<div class="about-us">
  <div class="container">
    <h1>{{'Company Profile' | translate}}</h1>

    <div class="row">
      <div class="col-12 col-md-4 director">
        <img src="assets/images/about-us/director.svg" alt="{{ 'Nurzhan Kozhakhmetovich Baybaliyev' | translate }}"
          title="{{ 'Nurzhan Kozhakhmetovich Baybaliyev' | translate }}" />
        <h5>{{ 'Nurzhan Kozhakhmetovich Baybaliyev' | translate }}</h5>
        <span>Chief Executive Officer Alif Islamic Bank Ltd.</span>
      </div>
      <div class="col-12 col-md-8 info">
        <h3>© Alif Islamic bank Ltd.</h3>

        <p>{{'Private Company Alif Islamic Bank Ltd' | translate}}</p>
        <br>
        <p>{{'We provide financial' | translate}}</p>

        <div class="requisites">
          <h3>{{'Bank details' | translate}}</h3>
          <p>
            {{'BIN' | translate}}<br />
            {{'Bank CenterCredit' | translate}}<br />
            {{'Settlement account' | translate}}<br />
            {{'Beneficiary Code' | translate}}<br />
            {{'BIC' | translate}}
          </p>
        </div>


        <div class="contacts">
          <h3>{{'Contact Us' | translate}}:</h3>
          <p>
            {{'For enquiries, call' | translate}}: <a href="tel:+77085000333">+7 708 5000 333</a> <br />
            {{'Address in Astana city' | translate}} <br />
            {{'Address in Almaty city' | translate}} <br />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
