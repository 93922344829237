import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WooppayService {

  constructor(
    private http: HttpClient
  ) { }

  public pay(amount: number) {
    return this.http.post<{operationUrl: string}>(`${environment.apiUrl}/api/user/loan/payment_wooppay`, {
      amount: amount,
      back_url: environment.wooppay.backUrl
    });
  }
}
