<div class="modal fade" id="sendOtpConfirmModal" tabindex="-1" role="dialog" aria-labelledby="sendContractTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="close-block">
        <img data-dismiss="modal" src="assets/images/home/close.svg" />
      </div>
      <div class="modal-body form">
        <div class="row mt-3">
          <div class="col-12">
            <h4 class="mb-3">Подтверждение номера телефона</h4>
            <p>На номер {{phone}} было отправлено SMS с кодом подтверждения. Введите полученный код, чтобы
              продолжить.</p>
            <p>Если в течение 60 секунд не пришло SMS, проверьте указанный номер телефона и <a>получите код повторно</a>
            </p>
          </div>
          <div class="col-md-5 col-12">
            <label for="otp_code">SMS код</label>
            <input type="text" id="otp_code" [value]="otp_code" [(ngModel)]="otp_code" placeholder="Введите SMS код"
              [imask]="{mask: '000000'}" (keypress)="numericOnly($event)">
          </div>
          <div class="col-md-7 col-12 pt-4">
            <small>Код подтверждения должен состоять из 6 символов</small>
          </div>

          <div class="col-md-6 mt-3 offset-3">
            <button class="btn btn-outline-primary m-auto py-3 px-5"
              [ngClass]="otp_code.length !== 4 ? '' : 'btn-secondary'" [disabled]="!otp_code || otp_code.length !== 6"
              (click)="verifyOTP()">
              Далее
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
