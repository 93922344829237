import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { RequestForCredit } from "src/app/models/request";
import { Call } from "src/app/models/call";
import { Letter } from "src/app/models/letter";
import { ScoringApply } from "src/app/models/apply";

@Injectable({
  providedIn: "root",
})
export class RequestService {
  constructor(private http: HttpClient) {}

  public sendRequest(request: RequestForCredit) {
    // if (request.phone) {
    //   request.phone = '+' + request.phone.replace(/\D/g,'');
    // }
    let utmSourceStr = localStorage.getItem("utmSource");
    if (utmSourceStr == "trafficwave") {
      request.utm_source = 1;
    } else if (utmSourceStr == "sravnibank") {
      request.utm_source = 3;
    } else {
      request.utm_source = 2;
    }
    request.repayment_method = request.annuity ? 1 : 0;
    return this.http.post<RequestForCredit>(
      `${environment.apiUrl}/api/content/credit/`,
      request
    );
  }

  public scoringApply(request: ScoringApply) {
    request.utm_source = localStorage.getItem("utmSource");
    request.utm = JSON.parse(localStorage.getItem("utms"));
    return this.http.post<ScoringApply>(
      `${environment.apiUrl}/api/scoring/apply/`,
      request
    );
  }
  public scoringStart(otp_code, uuid) {
    return this.http.post(`${environment.apiUrl}/api/scoring/start/${uuid}/`, {
      otp_code,
    });
  }

  public callMe(contact: Call) {
    return this.http.post<Call>(
      `${environment.apiUrl}/api/content/call/me/`,
      contact
    );
  }

  public writeMe(letter: Letter) {
    return this.http.post<Letter>(
      `${environment.apiUrl}/api/content/email/`,
      letter
    );
  }
}
