<app-navbar-header></app-navbar-header>

<div class="contacts">
  <div class="container">
    <h1>{{'Contact Us' | translate}}</h1>

    <div class="row inform">
      <div class="col-12 col-lg-4">
        <span class="copyright">{{'© Alif Islamic Bank Ltd' | translate}}</span>

        <div class="office block">
          <span><span class="title">{{'Astana city' | translate}}:</span>
            {{'Esil district, Uly Dala avenue, 5/2, office 18, room 5' | translate}}</span>
        </div>
        <div class="office block">
          <span><span class="title">{{'Almaty city' | translate}}:</span>
            {{'Al-Farabi avenue, 77/1, 10th floor, office 56' | translate}}</span>
        </div>

        <div class="work block">
          <span class="title">{{'Business hours' | translate}}: </span>
          <span>{{'from 9:00 to 18:00 every day' | translate}}</span>
        </div>

        <div class="phone block">
          <span class="title">{{'Phone' | translate}}: </span>
          <span>
            <a href="tel:+77085000333">
              +7 (708) 500-03-33
            </a>
          </span>
        </div>
      </div>
      <div class="col-12 col-lg-8 map-block">
        <div id="mapShow" class="map"></div>
      </div>
    </div>

    <h3>{{'If you have any questions, please write to us' | translate}}</h3>

    <div class="send-msg-form">
      <div class="row">
        <div class="col-md-4">
          <span>{{'Your name' | translate}}</span>
          <input id="firstname" type="text" [(ngModel)]="newLetter.name" (focus)="checkToValid = true"
            (change)="requestErrors ? requestErrors.name = null : ''"
            [ngStyle]="{'border-color': checkToValid && (!newLetter.name || requestErrors.name) ? 'red' : ''}" />
        </div>
        <div class="col-md-4">
          <span>Email</span>
          <input id="email" type="text" [(ngModel)]="newLetter.email" placeholder="info@alifislamicbank.kz"
            (focus)="checkToValid = true" (change)="requestErrors ? requestErrors.email = null : ''"
            [ngStyle]="{'border-color': checkToValid && (!newLetter.email || requestErrors.email) ? 'red' : ''}" />
        </div>
        <div class="col-md-4">
          <span>{{'Phone' | translate}}</span>
          <input id="phone" #phone type="text" [imask]="{mask: '+7 (000) 000-00-00'}" [(ngModel)]="newLetter.phone"
            unmask="typed" (input)="checkInputVal(phone);" (focus)="checkToValid = true"
            [ngStyle]="{'border-color': checkToValid && (!newLetter.phone || requestErrors.phone || newLetter.phone.length < 18) ? 'red' : ''}" />
        </div>

        <div class="col-md-12">
          <span>{{'Message text' | translate}}</span>
          <textarea name="message" cols="30" rows="10" maxlength="3000" [(ngModel)]="newLetter.text"
            (focus)="checkToValid = true" (change)="requestErrors ? requestErrors.text = null : ''"
            [ngStyle]="{'border-color': checkToValid && (!newLetter.text || requestErrors.text) ? 'red' : ''}"></textarea>
        </div>
        <div class="col-md-12">
          <button mat-flat-button color="primary" class="btn btn-send" [disabled]="!checkFields(newLetter)"
            (click)="sendLetter(newLetter)">{{'Send' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
