<div class="modal fade" id="sendOtpModal" tabindex="-1" role="dialog" aria-labelledby="sendContractTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="close-block">
        <img data-dismiss="modal" src="assets/images/home/close.svg" />
      </div>
      <div class="modal-body form">
        <h3>Подписание договора посредством СМС</h3>
        <div class="row mt-3">
          <div class="col-12">
            <p>На ваш номер был отправлен OTP код</p>
          </div>
          <div class="col-md-6">
            <input type="tel" [value]="otp_code" [(ngModel)]="otp_code" placeholder="Введите OTP код" (keypress)="numericOnly($event)">
          </div>
          <div class="col-md-6">
            <small>
              <button class="btn btn-sm nav-tabs p-0 rounded-0 text-white" [ngClass]="disabled ? 'text-white-50' : ''" (click)="resendOTP()" [disabled]="disabled">
                Отправить СМС
              </button>
              <br /> повторно через {{countdown}}  сек
            </small>
          </div>
          <div class="col-12 mb-3 d-flex">
            <input class="inp-cbx" id="check1" type="checkbox" [value]="check1" (change)="onScoringChange(!check1, 'check1')" [checked]='check1' />
            <label class="cbx" for="check1">
              <span>
                <svg width="12px" height="10px">
                  <use xlink:href="#check"></use>
                </svg>
              </span>
            </label>
            <p>С <a [href]="printApplication" target="_blank" class="nav-tabs text-white">заявлением</a> на получение микрокредита по ИП ознакомлен(а) и согласен(а)</p>
          </div>
          <div class="col-12 mb-3 d-flex">
            <input class="inp-cbx" id="check2" type="checkbox" [value]="check2" (change)="onScoringChange(!check2, 'check2')" [checked]='check2' />
            <label class="cbx" for="check2">
              <span>
                <svg width="12px" height="10px">
                  <use xlink:href="#check"></use>
                </svg>
              </span>
            </label>
            <p>С <a [href]="printCreditContract" target="_blank" class="nav-tabs text-white">договором</a> и <a [href]="printCreditContractSchedule" target="_blank" class="nav-tabs text-white">графиком погашения</a> ознакомлен(а) и согласен(а)</p>
          </div>
          <svg class="inline-svg">
            <symbol id="check" viewbox="0 0 12 10">
              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </symbol>
          </svg>

          <div class="col-md-3">
          </div>
          <div class="col-md-6 mt-3">
            <button
              class="w-100 btn text-white" 
              [ngClass]="otp_code && check1 && check2 ? '' : 'btn-secondary'"
              [disabled]="!otp_code || !check1 || !check2"
              style="height: 70px;
              background: #1C3D9F;
              border-radius: 0;
              font-size: 20px;
              padding: 0 30px;"
              (click)="verifyOTP()">
              Подписать
            </button>
          </div>
          <div class="col-md-6 mt-3">
            <button 
              class="btn text-white btn-sm w-100" 
              (click)="rejectionVariant()"
              style="height: 40px;
              background: #1C3D9F;
              border-radius: 0;
              font-size: 14px;
              padding: 0 15px;">
              Отказ от микрокредита
            </button>
          </div>
          <div class="col-md-6 mt-3">
            <button 
              class="btn text-white btn-sm w-100" 
              (click)="choiceVariant()"
              style="height: 40px;
              background: #1C3D9F;
              border-radius: 0;
              font-size: 14px;
              padding: 0 15px;">
              Подписать другим способом
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
