<app-navbar-header></app-navbar-header>

<div class="pay-off">
    <div class="container">
        <h1>Как погасить займ</h1>
        <div class="row body">
            <div class="col-12 col-md-4">
                <div class="list-group" id="list-tab" role="tablist">
                    <a class="list-group-item list-group-item-action active"
                        id="list-qiwi-list"
                        data-toggle="list"
                        href="#list-qiwi"
                        role="tab"
                        aria-controls="qiwi-list">
                        Терминал Qiwi
                    </a>
                    <a class="list-group-item list-group-item-action"
                        id="list-qiwi-wallet-list"
                        data-toggle="list"
                        href="#list-qiwi-wallet"
                        role="tab"
                        aria-controls="qiwi-wallet-list">
                        QIWI кошелек
                    </a>
                    <a class="list-group-item list-group-item-action"
                        id="list-woopay-list"
                        data-toggle="list"
                        href="#list-woopay"
                        role="tab"
                        aria-controls="woopay-list">
                        Онлайн оплата через
                        Wooppay
                    </a>
                    <a class="list-group-item list-group-item-action"
                        id="list-bank-list"
                        data-toggle="list"
                        href="#list-bank"
                        role="tab"
                        aria-controls="bank-list">
                        Банковский перевод
                    </a>
                </div>
            </div>
            <div class="col-12 col-md-8">
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active"
                        id="list-qiwi"
                        role="tabpanel"
                        aria-labelledby="list-qiwi-list">
                        <h5>Оплата через QIWI-терминал</h5>
                        <p>
                            <span>1. На главной странице интерактивного меню нажмите кнопку «ОПЛАТА УСЛУГ»</span><br/>
                            <img src="assets/images/pay-off/qiwi/1.svg"
                                alt="1. На главной странице интерактивного меню нажмите кнопку «ОПЛАТА УСЛУГ»"
                                title="1. На главной странице интерактивного меню нажмите кнопку «ОПЛАТА УСЛУГ»"/>
                        </p>
                        <p>
                            <span>2. Перейдите в раздел «Погашение кредитов/фин.услуги»</span><br/>
                            <img src="assets/images/pay-off/qiwi/2.svg"
                                alt="2. Перейдите в раздел «Погашение кредитов/фин.услуги»"
                                title="2. Перейдите в раздел «Погашение кредитов/фин.услуги»"/>
                        </p>
                        <p>
                            <span>3. Из представленного списка нажмите «МКО и МФО»</span><br/>
                            <img src="assets/images/pay-off/qiwi/3.svg"
                                alt="3. Из представленного списка нажмите «МКО и МФО»"
                                title="3. Из представленного списка нажмите «МКО и МФО»"/>
                        </p>
                        <p>
                            <span>4. Найдите «МФО ISKERCREDIT»в общем списке</span><br/>
                            <img src="assets/images/pay-off/qiwi/4.svg"
                                alt="4. Найдите «МФО ISKERCREDIT»в общем списке"
                                title="4. Найдите «МФО ISKERCREDIT»в общем списке"/>
                        </p>
                        <p>
                            <span>5. Введите свой ИИН</span><br/>
                            <img src="assets/images/pay-off/qiwi/5.svg"
                                alt="5. Введите свой ИИН"
                                title="5. Введите свой ИИН"/>
                        </p>
                        <p>
                            <span>6. Выберите оператора для перечисления сдачи и внесите наличные в купюроприемник</span><br/>
                            <img src="assets/images/pay-off/qiwi/6.svg"
                                alt="6. Выберите оператора для перечисления сдачи и внесите наличные в купюроприемник"
                                title="6. Выберите оператора для перечисления сдачи и внесите наличные в купюроприемник"/>
                        </p>
                        <p>
                            <span>7. Возьмите квитанцию и сохраняйте ее до поступления денег на счет.</span>
                        </p>
                    </div>
                    <div class="tab-pane fade"
                        id="list-qiwi-wallet"
                        role="tabpanel"
                        aria-labelledby="list-qiwi-wallet-list">

                        <h5>Оплата через QIWI-кошелек</h5>
                        <p>
                            <span>1. Необходимо перейти по ссылке: <a href="https://qiwi.com/" target="_blank">https://qiwi.com/</a></span>
                        </p>
                        <p>
                            <span>2. Зарегистрируйтесь и авторизуйтесь на сайте <a href="https://qiwi.com/" target="_blank">qiwi.com</a></span><br/>
                            <img src="assets/images/pay-off/qiwi-wallet/2.svg"
                                alt="2. Зарегистрируйтесь и авторизуйтесь на сайте qiwi.com"
                                title="2. Зарегистрируйтесь и авторизуйтесь на сайте qiwi.com"/>
                        </p>
                        <p>
                            <span>3. В «Личном кабинете» QIWI Wallet введите в строке поиска МФО ISKERCREDIT</span><br/>
                            <img src="assets/images/pay-off/qiwi-wallet/3.svg"
                                alt="3. В «Личном кабинете» QIWI Wallet введите в строке поиска МФО ISKERCREDIT"
                                title="3. В «Личном кабинете» QIWI Wallet введите в строке поиска МФО ISKERCREDIT"/>
                        </p>
                        <p>
                            <span>4. Введите свой ИИН</span>
                        </p>
                        <p>
                            <span>5. Выберите способ оплаты</span>
                        </p>
                        <p>
                            <span>6. Введите сумму к оплате</span><br/>
                            <img src="assets/images/pay-off/qiwi-wallet/6.svg"
                                alt="6. Введите сумму к оплате"
                                title="6. Введите сумму к оплате"/>
                        </p>
                        <p>
                            <span>7. Подтвердите правильность введенных данных</span>
                        </p>
                        <p>
                            <span>8. Дальнейшие шаги будут зависеть от выбранного способа <br/>
                                оплаты.</span>
                        </p>
                    </div>
                    <div class="tab-pane fade woopay"
                        id="list-woopay"
                        role="tabpanel"
                        aria-labelledby="list-woopay-list">
                        <h5>Оплата банковскими картами через «Wooppay»</h5>
                        <p>
                            <span class="title">К оплате принимаются карты VISA и MasterCard.</span><br/>
                            <span>Ввод данных банковской карты осуществляется на защищенной платежной <br/>странице «Wooppay».</span>
                        </p>
                        <p>
                            1. На странице для ввода данных банковской карты потребуется ввести номер <br/>карты,
                            имя владельца карты, срок действия карты, трёхзначный код безопасности <br/> (CVV2 для
                            VISA или CVC2 для MasterCard). Все необходимые данные пропечатаны на  <br/>самой карте.
                            Трёхзначный код безопасности — это три цифры, находящиеся на обратной стороне
                            карты.
                        </p>
                        <p>
                            2. Далее вы будете перенаправлены на страницу Вашего банка для ввода <br/> 3DSecure кода,
                            который придет к Вам в СМС. Если 3DSecure код к Вам не пришел,<br/>  то следует обратится
                            в банк выдавший Вам карту.
                        </p>
                        <div class="event-block">
                            <p>
                                <span class="title-margin">Случаи отказа в совершении платежа:</span>
                                <span>- Банковская карта не предназначена для совершения платежей через интернет, <br/>о чем
                                        можно узнать, обратившись в Ваш Банк;</span>
                            </p>
                            <p>
                                <span>- Недостаточно средств для оплаты на банковской карте. Подробнее о наличии <br/>средств
                                        на банковской карте Вы можете узнать, обратившись в банк, <br/>выпустивший банковскую
                                        карту;</span>
                            </p>
                            <p>
                                <span>- Данные банковской карты введены неверно;</span>
                            </p>
                            <p>
                                <span>- Истек срок действия банковской карты. Срок действия карты, как правило, <br/>указан на
                                        лицевой стороне карты (это месяц и год, до которого действительна<br/> карта). Подробнее
                                        о сроке действия карты Вы можете узнать, обратившись в банк, <br/>выпустивший
                                        банковскую карту;
                                </span>
                            </p>
                        </div>
                        <div class="event-block">
                            <p>
                                <span>- По вопросам оплаты с помощью банковской карты Вы можете <br/>обращаться по
                                        следующим телефонам:
                                </span>
                                <span class="phone">+7(707)444-17-48</span> а также <span class="phone">+7(700)444-17-68</span>
                            </p>
                        </div>
                        <div class="event-block">
                            <p>
                                <span>
                                        Предоставляемая вами персональная информация (имя, адрес, телефон, e-mail,<br/> номер
                                        банковской карты) является конфиденциальной и не подлежит разглашению.<br/> Данные
                                        вашей кредитной карты передаются только в зашифрованном виде и <br/>не сохраняются на
                                        нашем Web-сервере.
                                </span>
                            </p>
                        </div>

                        <a mat-flat-button color="primary" class="btn wooppay"
                          href="https://www.wooppay.com/services/mfoquantum_parent">
                          Внести оплату через Wooppay
                        </a>
                    </div>
                    <div class="tab-pane fade bank"
                        id="list-bank"
                        role="tabpanel"
                        aria-labelledby="list-bank-list">
                        <h5>Посредством банковского перевода</h5>
                        <p>
                            <span>
                                Посредством банковского перевода Вы можете погасить займ в кассе любого банка<br/> Казахстана, указав
                                наши полные реквизиты. Также возможно через <br/> интернет-банкинг Вашего банка, или
                                через любой банк, в котором у Вас открыт банковский счет.
                            </span>
                        </p>
                        <div class="event-block">
                            <p>
                                <span class="title-margin">Укажите: </span>
                                <span>- Получатель: ТОО «МФО Quantum»</span>
                            </p>
                            <p>
                                <span>- Назначение платежа: Ваш ИНН</span>
                            </p>
                            <p>
                                <span>- Цель платежа: погашение займа</span>
                            </p>
                            <p>
                                <span>Эти данные понадобятся нам для идентификации вашего платежа.</span>
                            </p>
                        </div>
                        <div class="event-block">
                            <p>
                                <span class="title-margin">Наши реквизиты: </span>
                                <span>- БИН(ИИН): 190640025480</span>
                            </p>
                            <p>
                                <span>- Банк АО «Kaspi Bank»</span>
                            </p>
                            <p>
                                <span>- Расчетный счет: KZ07722S000002331984 KZT</span>
                            </p>
                            <p>
                                <span>- КБе :15</span>
                            </p>
                            <p>
                                <span>- БИК: CASPKZKA</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
