import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import {
  CalculatorProducts,
  CalculatorProperty,
  DdcapCalculate,
} from "src/app/models/calculator";

@Injectable({
  providedIn: "root",
})
export class CalcService {
  constructor(private http: HttpClient) {}

  public getCalcs(): Observable<Array<CalculatorProperty>> {
    return this.http.get<Array<CalculatorProperty>>(
      `${environment.apiUrl}/api/admin/calculator/`
    );
  }

  public add(body: any): Observable<CalculatorProperty> {
    return this.http.post<CalculatorProperty>(
      `${environment.apiUrl}/api/admin/calculator/`,
      body
    );
  }

  public update(body: any): Observable<CalculatorProperty> {
    return this.http.put<CalculatorProperty>(
      `${environment.apiUrl}/api/admin/calculator/${body.id}/`,
      body
    );
  }

  public remove(id: number): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiUrl}/api/admin/calculator/${id}/`
    );
  }

  public getProducts(): Observable<any> {
    return this.http.get<Array<CalculatorProducts>>(
      `${environment.apiUrl}/api/content/products/`
    );
  }

  public ddcapCalculate(
    desired_amount: number,
    desired_period: number
  ): Observable<DdcapCalculate> {
    return this.http.post<DdcapCalculate>(
      `${environment.apiUrl}/ddcap/calculate/`,
      { desired_amount, desired_period }
    );
  }
}
