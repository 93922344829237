import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject } from "rxjs";
import { TimeSnackbarShow } from "src/app/config";
import { RequestService } from "src/app/services/request/request.service";
import { UserService } from "src/app/services/user/user.service";

declare let $: any;

@Component({
  selector: "app-modal-tranche",
  templateUrl: "./modal-tranche.component.html",
  styleUrls: ["./modal-tranche.component.scss"],
  providers: [MatSnackBar],
})
export class ModalTrancheComponent implements OnInit, OnDestroy {
  @Input() contractId: number;
  @Output() sendTrancheModal = new EventEmitter<Object>();
  private destroyed$ = new Subject();

  constructor(
    private requestService: RequestService,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) {}

  public checkToValid = false;
  public requestErrors: any = {};
  public tranche: number;

  ngOnInit() {}

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  public sendTranche() {
    this.userService
      .setTranches(this.contractId, this.tranche, "ANNUITY")
      .subscribe(
        (res: any) => {
          this.sendTrancheModal.emit();
          this.tranche = null;
          $("#trancheModal").modal("hide");
        },
        (err) => {
          this.requestErrors = err.error;
          let errMsg = "";
          if (err.error && err.error.errors) {
            for (let i = 0; i < err.error.errors.length; i++) {
              errMsg += err.error.errors[i].message + "\n";
            }
          } else {
            for (let key in err.error) {
              errMsg += err.error[key] + "\n";
            }
          }

          this.snackBar.open(errMsg || "Ошибка при отправке!", null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
          this.tranche = null;
        }
      );
  }
}
