// core
import { Component, OnInit, OnDestroy, AfterContentInit } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
// models
import { User } from "../../models/user";

// services
import { UnsubscribeService } from "../../services/unsubscribe/unsubscribe.service";

declare let $: any;
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, OnDestroy {
  subscribes = new Array<any>();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private unsubscribeService: UnsubscribeService
  ) {}

  year = new Date().getFullYear();

  ngOnInit() {}

  anchor(url: string, id: string) {
    if (this.router.url === url) {
      this.scrollToElement(id);
    } else {
      this.router.navigate(["/"]).then(() => {
        this.scrollToElement(id);
      });
    }
  }

  scrollToElement(id: string) {
    const element: HTMLElement = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 100,
        behavior: "smooth",
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribeService.unsubscribings(this.subscribes);
  }
}
