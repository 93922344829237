<div class="modal fade" id="modalSign" tabindex="-1" role="dialog" aria-labelledby="sendContractTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="close-block">
        <img data-dismiss="modal" src="assets/images/home/close.svg" />
      </div>
      <div class="modal-body form">
        <h4 class="mb-4">Подписание договора о предоставлении микрокредита</h4>
        <p>На номер +7 (777) 777-77-77 было отправлено SMS с кодом подтверждения. Введите полученный код, чтобы
          подписать Договор.</p>
        <p>Если в течение 60 секунд не пришло SMS, получите код повторно.</p>
        <div class="row form-group">
          <div class="col-12 col-md-3">
            <label for="sms">SMS код</label>
            <input type="text" #sms name="sms" id="sms" class="form-control" required />
          </div>
          <div class="col-12 col-md-5">
            <label for="phone">Мобильный телефон*</label>
            <input type="text" #phone name="phone" id="phone" class="form-control" placeholder="+ 7 (XXX) XXXX XXX"
              required />
          </div>
          <div class="col-12 col-md-4 d-flex align-items-center">
            <p><small>Код отправлен (57)</small></p>
          </div>
          <button mat-flat-button color="primary" (click)="closeModal()" class="btn primary col-12 mt-3" style="height: 70px;
          background: #FF9918;
          border-radius: 0;
          font-size: 20px;
          padding: 0 30px;">Подписать
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
