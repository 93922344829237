<div class="modal fade" id="trancheModal" tabindex="-1" role="dialog" aria-labelledby="sendContractTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="close-block">
        <img data-dismiss="modal" src="assets/images/home/close.svg" />
      </div>
      <div class="modal-body form">
        <div class="row">
          <div class="col-12">
            <h4 class="mb-3">Заявка на транш</h4>
          </div>
          <div class="col-12">
            <label for="tranche">Сумма в тенге</label>
            <input type="text" id="tranche" [value]="tranche" [(ngModel)]="tranche" placeholder="Введите сумму транша"
              (keypress)="numericOnly($event)">
          </div>

          <div class="col-12 d-flex justify-content-center">
            <button class="btn btn-outline-primary m-auto py-3 px-5" (click)="sendTranche()">
              Отправить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
