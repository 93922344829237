<div class="modal fade" id="requestModal" tabindex="-1" role="dialog" aria-labelledby="requestModalTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="close-block">
        <img data-dismiss="modal" src="assets/images/home/close.svg" />
      </div>
      <div class="modal-body">
        <h3>{{'Apply now' | translate}}</h3>
        <div class="col-12 form">
          <div>
            <label for="mobile_phone">{{'Mobile phone' | translate}}</label>
            <input type="text" name="mobile_phone" #mobile_phone id="mobile_phone" required
              placeholder="Мобильный телефон" [imask]="{mask: '+7 (000) 000-00-00'}" unmask="typed"
              [(ngModel)]="newRequest.mobile_phone" (focus)="checkToValid = true"
              [ngStyle]="{'border-color': checkToValid && (!newRequest.mobile_phone || requestErrors.mobile_phone || newRequest.mobile_phone.length < 18) ? 'red' : ''}"
              (input)="checkInputVal(mobile_phone);" />
          </div>

          <div>
            <label for="inn">{{'Your IIN' | translate}}</label>
            <input type="number" name="inn" id="inn" required placeholder="{{'Your IIN' | translate}}"
              [imask]="{mask: '000000000000'}" unmask="typed" [(ngModel)]="newRequest.iin" (focus)="checkToValid = true"
              (change)="requestErrors ? requestErrors.iin = null : ''"
              [ngStyle]="{'border-color': checkToValid && (!newRequest.iin || requestErrors.iin) ? 'red' : ''}" />
          </div>

          <div>
            <label for="desired_amount">
              {{'Amount in KZT' | translate}} ({{product?.principal_limits?.lower | numseparator}} -
              {{product?.principal_limits?.upper | numseparator}})
            </label>
            <input type="text" name="desired_amount" id="desired_amount" required
              placeholder="{{'Amount in KZT' | translate}} ({{product?.principal_limits?.lower | numseparator}} - {{product?.principal_limits?.upper | numseparator}})"
              [(ngModel)]="newRequest.desired_amount" (focus)="checkToValid = true" [ngStyle]="{
            'border-color': checkToValid &&
            (!newRequest.desired_amount || requestErrors.desired_amount)
            ? 'red'
            : ''
          }" (focusout)="checkDesiredAmountToMaxMin(product?.principal_limits?.lower, product?.principal_limits?.upper)"
              onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
          </div>

          <div>
            <label for="city">{{'City' | translate}}</label>
            <select name="city" id="city" (change)="onSelect($event.target.value)" [value]="newRequest.city"
              placeholder="Город">
              <option value="" disabled checked></option>
              <option value="1">{{'Almaty' | translate}}</option>
              <option disabled>{{'Nur-Sultan (coming soon)' | translate}}</option>
              <option disabled>{{'Shymkent (coming soon)' | translate}}</option>
            </select>
          </div>

          <div>
            <label for="desired_period">{{'Period' | translate}} ({{product?.period_limits?.lower}} -
              {{product?.period_limits?.upper}} {{'months' | translate}})</label>

            <select name="desired_period" id="desired_period" [(ngModel)]="newRequest.desired_period"
              (focus)="checkToValid = true" class="form-select w-100"
              [ngStyle]="{'border-color': checkToValid && (!newRequest.desired_period || requestErrors.desired_period) ? 'red' : ''}"
              (focusout)="checkDesiredPeriodToMaxMin()">
              <option value="0" selected disabled>{{'Period' | translate}} ({{product?.period_limits?.lower}} -
                {{product?.period_limits?.upper}} {{'months' | translate}})</option>
              <option *ngFor="let month of monthly_payment" [value]="month">
                {{month}}
              </option>
            </select>
          </div>

          <div>
            <label for="product_name">{{'Type of product' | translate}}</label>
            <select name="select" id="product_name" (change)="onSelectProduct($event.target.value)" [value]="product.id"
              class="form-select w-100">
              <option value="" selected disabled>{{'Select an option' | translate}}</option>
              <option *ngFor="let product of products" [value]="product.id">
                {{product.name}}
              </option>
            </select>
          </div>

          <div class="accept-condition-block">
            <mat-checkbox [(ngModel)]="newRequest.agree_personal_data"
              [class.error-mat-checkbox]="checkToValid && (!newRequest.agree_personal_data || requestErrors.agree_personal_data)">
              {{'Consent to collection and processing of personal information' | translate}}
            </mat-checkbox>
            <mat-checkbox [(ngModel)]="newRequest.agree_contact_details"
              [class.error-mat-checkbox]="checkToValid && (!newRequest.agree_contact_details || requestErrors.agree_contact_details)">
              {{'Consent to providing and issuing the First Credit Bureau Report' | translate}}
            </mat-checkbox>
            <!-- <mat-checkbox [(ngModel)]="newRequest.agree_agent_contact"
              [class.error-mat-checkbox]="checkToValid && (!newRequest.agree_agent_contact || requestErrors.agree_agent_contact)">
              Я ознакомлен(а) с Агентским соглашением
            </mat-checkbox> -->
            <mat-checkbox [(ngModel)]="newRequest.agree_finance"
              [class.error-mat-checkbox]="checkToValid && (!newRequest.agree_finance || requestErrors.agree_finance)">
              {{'I confirm applying for financing' | translate}}
            </mat-checkbox>
          </div>

          <div class="row justify-content-center mt-4">
            <button class="btn btn-outline-primary m-auto py-3 px-5" (click)="sendConfirmOTP(newRequest)"
              [disabled]="!checkRequest(newRequest)">
              {{'Send' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
