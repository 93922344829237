<header class="header">
  <nav class="navbar fixed-top navbar-expand-lg navbar-light">
    <div class="container header-container">
      <div class="row">
        <div class="col-12 col-lg-2 logo-nav d-flex align-items-center">
          <a routerLink="/" class="logo">
            <img src="assets/images/nav-bar/logo_new.svg" alt="IskerСredit" class="w-100" />
          </a>

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>

        <div class="col-12 col-lg-7 d-flex align-items-center justify-content-center">
          <div class="navbar-wrapper">
            <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <a routerLink="/about-us" class="nav-link">{{ 'About Us' | translate }}</a>
                </li>
                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <a routerLink="/products" class="nav-link">{{ 'Products' | translate }}</a>
                </li>
                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <a data-toggle="modal" data-target="#councilModal"
                    class="nav-link">{{ 'Shariah Board' | translate }}</a>
                </li>
                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <a data-toggle="modal" data-target="#financeModal" class="nav-link">{{ 'Financing' | translate }}</a>
                </li>
                <div role="separator" class="dropdown-divider"></div>
                <li class="nav-item d-lg-none">
                  <div class="input-group-append position-relative mb-3 mt-1">
                    <button class="lang text-uppercase" type="button" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">{{lang}}</button>
                    <div class="dropdown-menu">
                      <span class="dropdown-item" [class.active]="lang === 'ru'" (click)="changeLang('ru')">RU</span>
                      <span class="dropdown-item" [class.active]="lang === 'en'" (click)="changeLang('en')">EN</span>
                    </div>
                  </div>
                </li>
                <li class="nav-item add-mob-menu" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <div class="auth-block-mobile" *ngIf="token; else notAuth">
                    <a color="primary" class="btn py-2" routerLink="/profile">
                      {{ 'My profile' | translate }}
                    </a>
                    <a class="logout" (click)="logout()">{{'Logout' | translate}}</a>
                  </div>
                  <ng-template #notAuth>
                    <a color="primary" class="btn py-2" routerLink="/profile">
                      {{ 'My profile' | translate }}
                    </a>
                  </ng-template>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-6 col-lg-3 go-profile d-lg-flex d-none">
          <div class="d-lg-flex d-none align-items-center justify-content-center mr-3">
            <div class="input-group-append position-relative">
              <button class="lang text-uppercase" type="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">{{lang}}</button>
              <div class="dropdown-menu">
                <span class="dropdown-item" [class.active]="lang === 'ru'" (click)="changeLang('ru')">RU</span>
                <span class="dropdown-item" [class.active]="lang === 'en'" (click)="changeLang('en')">EN</span>
              </div>
            </div>
          </div>
          <div class="auth-block text-right" *ngIf="token; else notAuth">
            <a mat-flat-button color="primary" class="btn mb-2 wooppay" routerLink="/profile">
              {{ 'My profile' | translate }}
            </a>
            <a class="logout" (click)="logout()">{{'Logout' | translate}}</a>
          </div>
          <ng-template #notAuth>
            <a mat-flat-button color="primary" class="btn py-1 wooppay" routerLink="/profile">
              {{ 'My profile' | translate }}
            </a>
          </ng-template>
        </div>
      </div>

    </div>

  </nav>
</header>

<!-- Modal -->
<div class="modal fade" id="councilModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content condition-block">
      <div class="modal-body title">
        <div class="close-block close-request-block d-flex justify-content-end">
          <img data-dismiss="modal" src="assets/images/home/close.svg" />
        </div>
        <h4 class="text-center mb-3">{{'Shariah Board of Alif Islamic Bank Ltd' | translate}}</h4>
        <div class="row mb-lg-3 mb-5">
          <div class="col-12 col-lg-4 photo">
            <img src="assets/images/shariat/3.jpg" class="w-100 rounded">
          </div>
          <div class="col-12 col-lg-8">
            <h5>{{'Maksatbek Kairgaliyev' | translate}}</h5>
            <p class="mb-2">{{'A Shariah Expert with many years of experience in teaching' | translate}}</p>

            <a href="https://www.linkedin.com/in/maqsadbek-qazaqi-98837368/" target="_blank">LinkedIn &#8594;</a>
          </div>
        </div>
        <div class="row mb-lg-3 mb-5">
          <div class="col-12 col-lg-4 photo">
            <img src="assets/images/shariat/4.jpg" class="w-100 rounded">
          </div>
          <div class="col-12 col-lg-8">
            <h5>{{'Altaf Ahmad' | translate}}</h5>
            <p class="mb-2">{{'An Expert in Fiqh al Muamalat' | translate}}</p>
            <a href="https://www.linkedin.com/in/dr-mufti-altaf-ahmad-secp-aaoifi-hies-jduk-a8277343"
              target="_blank">LinkedIn &#8594;</a>
          </div>
        </div>
        <div class="row mb-lg-3 mb-5">
          <div class="col-12 col-lg-4 photo">
            <img src="assets/images/shariat/1.jpg" class="w-100 rounded">
          </div>
          <div class="col-12 col-lg-8">
            <h5>{{'Adilbek Ryskulov' | translate}}</h5>
            <p>{{'An Expert with over 12 years of experience in Islamic banking and finance' | translate}}</p>
            <a href="https://www.linkedin.com/in/adilbek-ryskulov/" target="_blank">LinkedIn &#8594;</a>
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <button class="btn btn-outline-primary m-auto py-3 px-5"
            data-dismiss="modal">{{ 'Collapse' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="financeModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content condition-block">
      <div class="modal-body title">
        <div class="close-block close-request-block d-flex justify-content-end">
          <img data-dismiss="modal" src="assets/images/home/close.svg" />
        </div>
        <h4 class="text-center mb-3">{{'OUR FINANCIAL SOLUTIONS' | translate}}</h4>
        <h5 class="text-center mb-5">{{ 'are consistent with the ethical standards of Shariah' | translate }}</h5>
        <div class="row mb-lg-3 mb-5">
          <div class="col-12 col-lg-4 photo">
            <img src="assets/images/new/img5.png" class="w-100 rounded">
          </div>
          <div class="col-12 col-lg-8">
            <p class="mb-2">{{ 'For development of small and medium businesses' | translate }}</p>
            <p>Alif Islamic Bank Ltd. {{ 'offers easy working capital financing through' | translate }}</p>
            <p>{{ 'Apply for financing and achieve success' | translate }}</p>
            <p>{{'The Tawarruq transaction includes purchase' | translate}}</p>
          </div>
        </div>
        <div class="row mb-lg-3 mb-5">
          <div class="col-12 col-lg-4 photo">
            <img src="assets/images/new/img6.png" class="w-100 rounded">
          </div>
          <div class="col-12 col-lg-8">
            <p>{{'Under this transaction, Alif Islamic Bank Ltd' | translate}}</p>
            <p>{{'Financing terms' | translate}}:</p>
            <ul>
              <li>
                <p>{{'Amount: from 200,000 to 5,000,000 KZT' | translate}}</p>
              </li>
              <li>
                <p>{{'Period: from 13 months to 60 months' | translate}}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="row mb-lg-3 mb-5">
          <div class="col-12 col-lg-4 photo">
            <img src="assets/images/new/img7.png" class="w-100 rounded">
          </div>
          <div class="col-12 col-lg-8">
            <p>{{'Conditions according to Shariah' | translate}}:</p>
            <ul>
              <li>
                <p>{{'Amount: according to the product approved by the Shariah Board' | translate}}</p>
              </li>
              <li>
                <p>{{'Period: according to the product approved by the Shariah Board' | translate}}</p>
              </li>
            </ul>
            <p>{{'Who can apply for financing?' | translate}}</p>
            <ul>
              <li>
                <p>{{'Functioning Individual Entrepreneurs' | translate}}</p>
              </li>
              <li>
                <p>{{'Individual Entrepreneurs with the registration period of at least 6 months.' | translate}}</p>
              </li>
            </ul>
            <p>{{'In addition' | translate}}:</p>
            <ul>
              <li>
                <p>{{'Without collateral' | translate}}</p>
              </li>
              <li>
                <p>{{'For business development' | translate}}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <button class="btn btn-outline-primary m-auto py-3 px-5"
            data-dismiss="modal">{{ 'Collapse' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-wooppay></app-modal-wooppay>
