// cores
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// guards
import { MainGuard } from "./resolvers/main.guard";

// components
import { AboutUsComponent } from "./pages/about-us/about-us.component";
import { ProductsComponent } from "./pages/products/products.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { PayOffComponent } from "./pages/pay-off/pay-off.component";
import { DocumentsComponent } from "./pages/documents/documents.component";
import { ContactsComponent } from "./pages/contacts/contacts.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { FinancingComponent } from "./pages/financing/financing.component";

const routes: Routes = [
  {
    path: "about-us",
    component: AboutUsComponent,
  },
  {
    path: "products",
    component: ProductsComponent,
  },
  {
    path: "faq",
    component: FaqComponent,
  },
  {
    path: "pay-off",
    component: PayOffComponent,
  },
  {
    path: "documents",
    component: DocumentsComponent,
  },
  {
    path: "financing",
    component: FinancingComponent,
  },
  {
    path: "contacts",
    component: ContactsComponent,
  },
  {
    path: "auth",
    loadChildren: () =>
      import("../app/modules/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("../app/modules/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("../app/modules/profile/profile.module").then(
        (m) => m.ProfileModule
      ),
    canActivate: [MainGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("../app/modules/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
  // {
  //   path: 'login',
  //   loadChildren: 'src/app/modules/account/login.module#LoginModule'
  // },
  // {
  //   path: 'profile',
  //   loadChildren: 'src/app/modules/profile/profile.module#ProfileModule',
  //   canActivate: [MainGuard]
  // },
  // {
  //   path: '**',
  //   loadChildren: 'src/app/modules/home/home.module#HomeModule',
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routedComponents = [
  NotFoundComponent,
  ContactsComponent,
  DocumentsComponent,
  FinancingComponent,
  PayOffComponent,
  FaqComponent,
  AboutUsComponent,
  ProductsComponent,
];
