<div class="footer">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-md-4 mb-md-0 mb-5 align-items-baseline" style="display: grid;">
        <a routerLink="/" class="logo">
          <img src="assets/images/nav-bar/logo_new_white.svg" alt="© Alif Islamic Bank Ltd. - Исламский Банк на территории МФЦА, полностью
          соответсвующий нормам Шариата" />
        </a>
        <span class="align-self-end">©{{this.year}} {{'AFSA-A-LA-2022-0026' | translate}} <a
            href="https://publicreg.myafsa.com/licence_details/AFSA-A-LA-2022-0026/" target="_blank">AFSA</a></span>
      </div>
      <div class="col-md-4 mb-md-0 mb-5">
        <h5 class="mb-4">{{'Contact us' | translate}}:</h5>
        <p>
          <a href="tel:+77085000333">+7 708 5000 333</a>
        </p>
        <div class="d-flex align-items-center mb-3">
          <svg width="32" height="32" class="mr-3" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
              fill="#00DB80" />
            <path
              d="M20.2427 19.576L16 23.8187L11.7573 19.576C10.9182 18.7369 10.3468 17.6678 10.1153 16.5039C9.88378 15.34 10.0026 14.1336 10.4567 13.0373C10.9109 11.9409 11.6799 11.0038 12.6666 10.3446C13.6533 9.68527 14.8133 9.33337 16 9.33337C17.1867 9.33337 18.3467 9.68527 19.3334 10.3446C20.3201 11.0038 21.0891 11.9409 21.5433 13.0373C21.9974 14.1336 22.1162 15.34 21.8847 16.5039C21.6532 17.6678 21.0818 18.7369 20.2427 19.576ZM16 16.6667C16.3536 16.6667 16.6928 16.5262 16.9428 16.2761C17.1929 16.0261 17.3333 15.6869 17.3333 15.3333C17.3333 14.9797 17.1929 14.6406 16.9428 14.3905C16.6928 14.1405 16.3536 14 16 14C15.6464 14 15.3072 14.1405 15.0572 14.3905C14.8071 14.6406 14.6667 14.9797 14.6667 15.3333C14.6667 15.6869 14.8071 16.0261 15.0572 16.2761C15.3072 16.5262 15.6464 16.6667 16 16.6667Z"
              fill="white" />
          </svg>
          <div class="w-75">
            <span>{{'Astana city' | translate}}:
              {{'Esil district, Uly Dala avenue, 5/2, office 18, room 5' | translate}}</span>
          </div>
        </div>
        <div class="d-flex align-items-center mb-3">
          <svg width="32" height="32" class="mr-3" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
              fill="#00DB80" />
            <path
              d="M20.2427 19.576L16 23.8187L11.7573 19.576C10.9182 18.7369 10.3468 17.6678 10.1153 16.5039C9.88378 15.34 10.0026 14.1336 10.4567 13.0373C10.9109 11.9409 11.6799 11.0038 12.6666 10.3446C13.6533 9.68527 14.8133 9.33337 16 9.33337C17.1867 9.33337 18.3467 9.68527 19.3334 10.3446C20.3201 11.0038 21.0891 11.9409 21.5433 13.0373C21.9974 14.1336 22.1162 15.34 21.8847 16.5039C21.6532 17.6678 21.0818 18.7369 20.2427 19.576ZM16 16.6667C16.3536 16.6667 16.6928 16.5262 16.9428 16.2761C17.1929 16.0261 17.3333 15.6869 17.3333 15.3333C17.3333 14.9797 17.1929 14.6406 16.9428 14.3905C16.6928 14.1405 16.3536 14 16 14C15.6464 14 15.3072 14.1405 15.0572 14.3905C14.8071 14.6406 14.6667 14.9797 14.6667 15.3333C14.6667 15.6869 14.8071 16.0261 15.0572 16.2761C15.3072 16.5262 15.6464 16.6667 16 16.6667Z"
              fill="white" />
          </svg>
          <div class="w-75">
            <span>{{'Almaty city' | translate}}: {{'Al-Farabi avenue, 77/1, 10th floor, office 56' | translate}}</span>
          </div>
        </div>
        <div class="d-flex align-items-center ">
          <svg width="32" height="32" class="mr-3" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
              fill="#00DB80" />
            <path
              d="M10.0002 10H22.0002C22.177 10 22.3465 10.0702 22.4716 10.1953C22.5966 10.3203 22.6668 10.4899 22.6668 10.6667V21.3333C22.6668 21.5101 22.5966 21.6797 22.4716 21.8047C22.3465 21.9298 22.177 22 22.0002 22H10.0002C9.82335 22 9.65378 21.9298 9.52876 21.8047C9.40373 21.6797 9.3335 21.5101 9.3335 21.3333V10.6667C9.3335 10.4899 9.40373 10.3203 9.52876 10.1953C9.65378 10.0702 9.82335 10 10.0002 10ZM16.0402 15.7887L11.7655 12.1587L10.9022 13.1747L16.0488 17.5447L21.1028 13.1713L20.2308 12.1627L16.0408 15.7887H16.0402Z"
              fill="white" />
          </svg>
          <div class="w-75">
            <p class="m-0">
              <a href="mailto:info@alifislamicbank.kz">info@alifislamicbank.kz</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-md-0 mb-5">
        <h5 class="mb-4">{{'General information' | translate}}:</h5>
        <p>
          <a routerLink="/contacts">{{ 'Contact Us' | translate }}</a>
        </p>
        <p>
          <a routerLink="/documents">{{'Documents' | translate}}</a>
        </p>
        <p>
          <a routerLink="/faq">FAQ</a>
        </p>
      </div>
    </div>
  </div>
</div>
